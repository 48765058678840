/* src/App.css */
body {
  font-family: 'Press Start 2P', cursive; /* Retro font */
  background-color: #000; /* Black for retro feel */
  color: #fff; /* White text for high contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;  
  cursor: default; /* This sets the cursor to the default pointer */
}

/* src/App.css */
body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.snake-canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1; /* Ensures it stays behind the main content */
}

.content {
  position: relative;
  z-index: 1; /* Ensures the main content stays above the game */
}

/* src/App.css */
@keyframes blinkCursor {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.custom-cursor {
  width: 8px; /* Width of the block cursor */
  height: 16px; /* Height of the block cursor, adjust based on your text size */
  background-color: #00ff00; /* Retro green color */
  position: fixed;
  pointer-events: none; /* Ensure it doesn't interfere with clicking */
  animation: blinkCursor 1s steps(1) infinite;
}

.margin-left-15 {
  margin-left: 15px;
}

.App {
  box-shadow: 0 4px 15px rgba(0, 255, 0, 0.75); /* Neon glow for modern touch */
  border: 3px solid #00ff00; /* Bright green border for retro style */
  border-radius: 10px;
  padding: 20px;
  margin:10px;
  
  max-width: 900px; /* Increase maximum width */
  background: rgba(0, 0, 0, 0.85); /* Dark semi-transparent background */
}


h1, h2 {
  text-transform: uppercase;
  margin: 0.5em 0;
}

h1 {
  color: #ff0000; /* Red color for headings */
}

h2 {
  color: #00ff00; /* Green for subheadings */
}

p, li {
  color: #ffffff; /* White for general text */
  transition: color 0.3s;
}

p:hover, li:hover {
  color: #ffff00; /* Yellow on hover for interactive feel */
}

ul {
  text-align: left;
  list-style: none;
  padding: 0;
}

li {
  margin: 10px 0;
}

a {
  color: #00ff00; /* Links in green */
  text-decoration: none;
  border-bottom: 1px dotted #00ff00; /* Dotted underline for links */
}

a:hover {
  color: #ffff00; /* Yellow on hover for links */
  border-color: #ffff00;
}

footer {
  margin-top: 20px;
  font-size: 0.8em;
}

footer a {
  color: #0a66c2; /* LinkedIn's brand color */
  text-decoration: none;
  border-bottom: 1px dotted #0a66c2;
}

footer a:hover {
  color: #ffffff; /* White on hover for contrast */
  border-color: #ffffff;
}

/* Add to src/App.css */
@media (max-width: 600px) {
  .App {
    
    padding: 10px;
  }

  h1, h2 {
    font-size: 0.9rem;
  }

  footer {
    font-size: 0.7rem;
  }
}

/* Add to src/App.css */
/* src/App.css */
@keyframes glitch {
  0% {
    text-shadow: 2px 0 red;
    transform: translate(2px, 0);
  }
  20% {
    text-shadow: -2px 0 blue;
    transform: translate(-2px, 0);
  }
  40% {
    text-shadow: 2px 0 green;
    transform: translate(2px, -2px);
  }
  60% {
    text-shadow: -2px 0 yellow;
    transform: translate(-2px, 2px);
  }
  80% {
    text-shadow: 0 2px red;
    transform: translate(0, -2px);
  }
  100% {
    text-shadow: 0 -2px blue;
    transform: translate(0, 2px);
  }
}

.glitched {
  
  position: relative;
  animation: glitch 1s ease-out;
}


/* src/App.css */
.start-button {
  font-family: 'Press Start 2P', cursive; /* Retro gaming font */
  font-size: 20px;
  color: white; /* Bright green typical of retro computer displays */
  text-align: center;
  margin: 20px;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% { color: white; }
  50% { color: transparent; }
  100% { color: white; }
}